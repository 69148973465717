import React, { useState } from "react";
import { connect } from "react-redux";
import * as PropTypes from "prop-types";
import { get } from "lodash";
import { useIsMedium } from "../hooks";
import {
  FRIDGE_IS_CLOSED_BUY,
  FRIDGE_IS_CLOSED_DELIVER,
  FRIDGE_IS_OPENED_DELIVER,
  THANKS,
  FRIDGE_IS_OPENED_BUY,
  FRIDGE_IS_OPENED_REMOVE,
  FRIDGE_IS_OPENED_MAINTENANCE,
  FRIDGE_IS_CLOSED_REMOVE,
  FRIDGE_IS_CLOSED_MAINTENANCE,
  FRIDGE_IS_OPENING,
  MENU_LG,
  MENU,
  LOADING,
  ERROR,
  NO_OFFERS,
} from "../../services/i18n/messages";
import translated from "../../services/i18n/translated";
import styles from "./fridgedoorstatus.module.scss";
import FridgeOpened from "../fridge-opened/FridgeOpened";
import Loader from "../../assets/fridge.gif";
import Loading from "../common/icons/Loading";
import {
  BUY_TYPES,
  DOOR_CLOSED,
  DOOR_OPENED,
  INITIALIZED,
} from "../../services/helpers/order";
import DoorSteps from "../common/DoorSteps";
import BigText from "../common/BigText";
import { offersToProductList } from "../../services/helpers/offer";
import ProductList from "../common/ProductList";
import ProductPopup from "../fridge-popup/ProductPopup";
import Notification from "../fridge-popup/Notification";

const CLOSE_DOOR_POPUP_DELAY = 540 * 1000;

const FridgeDoorStatus = ({
  translate,
  fridge,
  order,
  products,
  loading,
  error,
  interactions,
}) => {
  const type = order ? order.type : "fallback";
  const messages = {
    [INITIALIZED]: {
      fallback: FRIDGE_IS_OPENING,
    },
    [DOOR_OPENED]: {
      deliver: FRIDGE_IS_OPENED_DELIVER,
      remove: FRIDGE_IS_OPENED_REMOVE,
      maintenance: FRIDGE_IS_OPENED_MAINTENANCE,
      fallback: FRIDGE_IS_OPENED_BUY,
    },
    [DOOR_CLOSED]: {
      deliver: FRIDGE_IS_CLOSED_DELIVER,
      remove: FRIDGE_IS_CLOSED_REMOVE,
      maintenance: FRIDGE_IS_CLOSED_MAINTENANCE,
      fallback: FRIDGE_IS_CLOSED_BUY,
    },
  };
  const status = order ? order.status : INITIALIZED;
  const [selectedProduct, setSelectedProduct] = useState();
  const [showCloseDoorPopup, setShowCloseDoorPopup] = useState(false);

  React.useEffect(() => {
    let handle = null;
    if (status === DOOR_OPENED && BUY_TYPES.includes(type)) {
      handle = setTimeout(() => {
        setShowCloseDoorPopup(true);
      }, CLOSE_DOOR_POPUP_DELAY);
    } else if (status === DOOR_CLOSED) {
      setShowCloseDoorPopup(false);
    }
    return () => clearTimeout(handle);
  }, [setShowCloseDoorPopup, status, type]);

  const isMedium = useIsMedium();

  if (status === undefined) {
    throw new Error("Unsupported order status!");
  }

  const message = get(messages[status], type, messages[status].fallback);

  if (loading && interactions) {
    return <BigText>{translate(LOADING)}</BigText>;
  }

  if (error) {
    return <BigText>{translate(ERROR)}</BigText>;
  }

  if (!loading && products.length === 0) {
    return <BigText>{translate(NO_OFFERS)}</BigText>;
  }

  if (!interactions && showCloseDoorPopup) {
    return (
      <Notification
        type="closeFridge"
        onClick={() => setShowCloseDoorPopup(false)}
        showClose
      />
    );
  }

  if (selectedProduct) {
    return (
      <ProductPopup
        product={selectedProduct}
        onClose={() => setSelectedProduct(null)}
      />
    );
  }

  if (status === DOOR_OPENED && BUY_TYPES.includes(type)) {
    return (
      <>
        <FridgeOpened fridge={fridge} interactions={interactions} />
        <ProductList
          title={isMedium ? translate(MENU_LG) : translate(MENU)}
          products={products}
          showQuantity
          onProductClick={(p, target) => {
            if (!interactions) {
              setSelectedProduct(p);
            }
          }}
        />
      </>
    );
  }

  if (
    !interactions &&
    (status === INITIALIZED || loading) &&
    BUY_TYPES.includes(type)
  ) {
    return (
      <div className={styles.container}>
        <div className={styles["loader-container"]}>
          <div className={styles["loader-content"]}>
            <div className={styles.loader}>
              <Loading />
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (!interactions && status === DOOR_CLOSED && BUY_TYPES.includes(type)) {
    return (
      <>
        <DoorSteps step={3}>
          <div className={styles.label}>
            <span>{translate(THANKS)}</span>
            <span>{translate(FRIDGE_IS_CLOSED_BUY)}</span>
          </div>
        </DoorSteps>
        <ProductList
          title={isMedium ? translate(MENU_LG) : translate(MENU)}
          products={products}
          showQuantity
          onProductClick={(p, target) => {
            if (!interactions) {
              setSelectedProduct(p);
            }
          }}
        />
      </>
    );
  }

  return (
    <div className={styles.container}>
      {status === INITIALIZED && (
        <div className={styles["loader-container"]}>
          <div className={styles["loader-content"]}>
            <div className={styles.loader}>
              <Loading />
            </div>
          </div>
        </div>
      )}
      {status === DOOR_CLOSED && (
        <div className={styles["loader-container"]}>
          <div className={styles["loader-content"]}>
            <div className={styles.loader}>
              <img src={Loader} alt="fridge animation" />
            </div>
            <div className={styles.text}>{translate(THANKS)}</div>
          </div>
        </div>
      )}
      <div className={styles.message}>{translate(message)}</div>
    </div>
  );
};

FridgeDoorStatus.propTypes = {
  translate: PropTypes.func.isRequired,
  fridge: PropTypes.shape({
    slug: PropTypes.string.isRequired,
  }).isRequired,
  order: PropTypes.shape({
    status: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
  }).isRequired,
  interactions: PropTypes.bool.isRequired,
  products: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      quantity: PropTypes.number.isRequired,
    })
  ),
  loading: PropTypes.bool,
  error: PropTypes.instanceOf(Error),
};

FridgeDoorStatus.defaultProps = {
  products: [],
  loading: false,
  error: null,
};

const mapStateToProps = (state) => ({
  products: state.offers.data
    ? offersToProductList(state.offers.data["hydra:member"])
    : [],
  loading: state.offers.loading,
  error: state.offers.error,
});

export default connect(mapStateToProps)(translated(FridgeDoorStatus));
